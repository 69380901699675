body {
  // background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}
.countdown {
  font-size: 3rem;
  margin-bottom: 2rem;
}
nav.appbar {
  background-color: transparent;
}
.MainLogo {
  width: 40%;
}
.MainBanner{
width: 100%;
}
.LogosSection {
  img {
    width: 100%;
    &.large-image {
      width: 60%;
    }
  }
}

#registration,
#Agenda,
#Overview {
  margin: 3rem 0;
}

#Background1 {
  padding: 2rem 0;
  color: white;
  background: linear-gradient(
      0deg,
      rgba(22, 33, 62, 0.6),
      rgba(22, 33, 62, 0.6)
    ),
    url("http://localhost:3000/assets/hero-image.png") no-repeat;
    background-size: cover;
}

#Background2 {
  background-color: #ffffff;
  background-image: url("https://worldquantumday.org/wp-content/uploads/2023/03/waves-right.svg");
  background-repeat: no-repeat;
  background-size: cover;
  #Overview {
    .overview_settings {
      background-color: #ffecdf;
      padding: 2rem;
      gap: 1rem;
      @media only screen and (max-width: 768px) {
        padding: 1rem;
      }
      .item {
        padding: 2rem;
        background-color: rgb(255, 255, 255, 0.7);
        @media only screen and (max-width: 768px) {
          padding: .5rem;
        }
      }
    }
  }
}

.footer {
  background-color: white;
  text-align: center;
}

.slick-item{
  padding: 3rem;
  @media only screen and (max-width: 768px) {
    padding: .5rem;
  }
  .mobile{
    display: none;
  }
  .image{
    // justify-content: flex-end ;
    justify-content: center;
    @media only screen and (max-width: 768px) {
      justify-content: center;
    }
    
  }
}


.circle-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
